<template>
  <div>
    <v-toolbar
      color="rgb(238, 240, 248)"
      style="box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;"
      height="60"
    >
      <v-btn icon dark color="primary" @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="text-primary">
        Create New User <v-icon color="primary">mdi-chevron-right</v-icon>
        {{ steptitle }}
      </v-toolbar-title>
    </v-toolbar>
    <perfect-scrollbar
      class="scroll"
      style="height:calc(100vh - 130px);position: relative"
    >
      <div class="p-4">
        <v-tabs-items v-model="step">
          <v-tab-item>
            <Account ref="accountinfo" :propuser="user" @error="showmsg" />
          </v-tab-item>
          <v-tab-item v-if="role == 4">
            <keep-alive>
              <MedicalProfile ref="mfinfo" />
            </keep-alive>
          </v-tab-item>
          <v-tab-item v-if="role == 3">
            <keep-alive>
              <DoctorProfile ref="doctorinfo" />
            </keep-alive>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </perfect-scrollbar>
    <div>
      <v-row
        class="px-7"
        v-if="(step == 0 && role != 3 && role != 4) || step == 1"
      >
        <v-col v-if="step == 1">
          <v-btn
            block
            large
            rounded
            class="pushxl"
            @click="gotostep(0)"
            outlined
            color="primary"
          >
            Back
          </v-btn>
        </v-col>
        <v-col v-else>
          <v-btn
            @click="close()"
            block
            large
            rounded
            class="pushxl"
            outlined
            color="primary"
          >
            Cancel
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            :loading="processing"
            :disabled="processing"
            @click="submituser"
            large
            block
            color="primary"
            elevation="0"
            rounded
          >
            Add User
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="px-7" v-else>
        <v-col>
          <v-btn
            @click="close()"
            block
            large
            rounded
            class="pushxl"
            outlined
            color="primary"
          >
            Cancel
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            large
            block
            color="primary"
            elevation="0"
            rounded
            @click="gotostep(1)"
          >
            {{ buttontitle }}
            <v-icon dark right>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <!-- snackbar -->
    <v-snackbar v-model="snackbar" absolute top :multi-line="true">
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: ["role", "isActive"],
  data() {
    return {
      step: 0,
      snackbar: false,
      steptitle: "Account Information",
      buttontitle: "",
      error: "",
      processing: false,
      user: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        timezone: "",
        phone: "",
        preferred_lang: "",
        preferred_lang_obj: this.$util.getLanguages()[0],
        verify_email: "false",
        nationality: "",
        national_id: ""
      }
    };
  },
  watch: {
    isActive: function(flag) {
      if (flag) {
        if (this.role == 3) {
          this.buttontitle = "Practice & Profile Info";
        } else if (this.role == 4) {
          this.buttontitle = "Medical Info";
        }
      } else {
        this.steptitle = "Account Information";
      }
    }
  },
  components: {
    Account: () => import("./usercomp/account.vue"),
    DoctorProfile: () => import("./usercomp/doctor.vue"),
    MedicalProfile: () => import("./usercomp/medical.vue")
  },
  methods: {
    showmsg(e) {
      this.error = e;
      this.snackbar = true;
    },
    gotostep(s) {
      if (s == 0) {
        this.steptitle = "Account Inforamtion";
      }

      if (s == 1) {
        if (this.$refs.accountinfo.isValid()) {
          this.step = s;
          this.user = this.$refs.accountinfo.getUser();
          // console.log("user is", this.user);
          if (this.role == 3) {
            this.steptitle = "Practice & Profile Info";
          } else if (this.role == 4) {
            this.steptitle = "Medical Info";
          }
        } else return 0;
      }
      this.step = s;
    },
    async submituser() {
      this.user.role = this.role;
      this.user.preferred_lang = this.user.preferred_lang_obj.id;

      if (this.step == 0) {
        if (this.$refs.accountinfo.isValid()) {
          this.processing = true;
          this.user = this.$refs.accountinfo.getUser();
          if (this.user.newavatar) {
            var formData = new FormData();
            formData.append("data", this.user.newavatar);
            const { data } = await this.$api.simplePostFile("files", formData);
            //// console.log("file upload result id",data.data.id)
            this.user.avatar = data.data.id;
          }
          // console.log("creating new user", this.user);
          this.$user.createUser(this.user).then(() => {
            // console.log("return from crate user", data);
            this.processing = false;
            this.emitmsg("User Added Successfully");
            this.close();
          });
        } else return 0;
      } else {
        if (this.role == 3) {
          if (this.$refs.doctorinfo.isValid()) {
            this.processing = true;
            if (this.user.newavatar) {
              formData = new FormData();
              formData.append("data", this.user.newavatar);
              const { data } = await this.$api.simplePostFile(
                "files",
                formData
              );
              //// console.log("file upload result id",data.data.id)
              this.user.avatar = data.data.id;
            }
            let payload = {
              ...this.user,
              ...this.$refs.doctorinfo.getDoctor()
            };
            payload.practice_started = payload.practice_started + "-01-01";
            this.$doctor.createDoctor(payload).then(() => {
              // console.log("return from crate doctor", data);
              this.processing = false;
              this.emitmsg("Doctor Added Successfully");
              this.close();
            });
          }
        } else if (this.role == 4) {
          if (this.$refs.mfinfo.isValid()) {
            this.processing = true;
            if (this.user.newavatar) {
              formData = new FormData();
              formData.append("data", this.user.newavatar);
              const { data } = await this.$api.simplePostFile(
                "files",
                formData
              );
              //// console.log("file upload result id",data.data.id)
              this.user.avatar = data.data.id;
            }
            let payload = { ...this.user, ...this.$refs.mfinfo.getMF() };
            var splitbday = payload.birthday.split("/");
            payload.birthday =
              splitbday[2] + "-" + splitbday[1] + "-" + splitbday[0];
            // console.log("payload to send is", payload);
            this.$file.createPatient(payload).then(() => {
              // console.log("return from crate patient", data);
              this.processing = false;
              this.emitmsg("Patient Added Successfully");
              this.close();
            });
          }
        }
      }
    },
    close() {
      this.$emit("close");
    },
    emitmsg(msg) {
      this.$emit("update", msg);
    },
    reset() {
      this.$refs.accountinfo.reset();
      if (this.$refs.doctorinfo) this.$refs.doctorinfo.reset();
      this.step = 0;
      this.snackbar = false;
      this.steptitle = "Account Information";
      this.buttontitle = "";
      this.error = "";
    }
  },

  mounted() {}
};
</script>
