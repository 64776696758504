var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.usersList.users)?_c('v-card',{staticClass:"elevation-0 transparent rounded-lg",attrs:{"disabled":_vm.loading || _vm.iscaching}},[_c('v-toolbar',{staticClass:" mb-3 rounded-lg",staticStyle:{"box-shadow":"0 10px 20px 0 rgba(0, 0, 0, 0.05)"},attrs:{"color":"white"}},[_c('v-text-field',{staticStyle:{"width":"100%"},attrs:{"clearable":"","flat":"","dense":"","single-line":"","solo":"","hide-details":"","prepend-inner-icon":"mdi-magnify","label":_vm.$t('MF.search') + ' users'},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-select',{staticClass:"mx-2",staticStyle:{"width":"200px !important"},attrs:{"flat":"","hide-details":"","rounded":"","dense":"","color":"primary","background-color":"blue-grey lighten-5","height":"36px","items":_vm.keys,"single-line":"","label":'Status'},model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}}),(_vm.$root.admin.selectedRole === 3)?_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary","elevation":"0","rounded":"","loading":_vm.iscaching,"disabled":_vm.iscaching},on:{"click":_vm.recacheDoctors}},[_vm._v(" recache doctors ")]):_vm._e(),_c('v-btn',{attrs:{"color":"primary","elevation":"0","rounded":""},on:{"click":function($event){_vm.createUser = true}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"left":"","dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(_vm._s(_vm.$t("MF.add"))+" ")],1)],1),_c('v-data-table',{staticClass:"rounded-lg",attrs:{"headers":_vm.headers,"items":_vm.searchresult,"loading":_vm.loading,"flat":"","locale":this.$root.lang},on:{"update:items":function($event){_vm.searchresult=$event},"click:row":_vm.openuser},scopedSlots:_vm._u([{key:"item.timezone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-size-md"},[_vm._v(_vm._s(item.timezone))])]}},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',[_c('v-badge',{attrs:{"bordered":"","top":"","color":_vm.setColor(item.status),"dot":"","offset-x":"10","offset-y":"10"}},[_c('v-avatar',{attrs:{"color":"primary","size":"40"}},[(item.avatar != null)?_c('v-img',{attrs:{"src":item.avatar.data.thumbnails[0].url}}):_c('div',{staticClass:"white--text font-size-h4",staticStyle:{"text-transform":"uppercase","width":"100%"}},[_vm._v(" "+_vm._s(item.first_name.charAt(0))+" ")])],1)],1),_c('v-list-item-content',{staticClass:"mx-3"},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.status)+" ")])],1)],1)]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-size-md"},[_vm._v(_vm._s(item.email))])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-size-md"},[_vm._v(_vm._s(item.phone))])]}}],null,false,2652804567)}),_c('v-navigation-drawer',{staticStyle:{"border-radius":"0"},attrs:{"app":"","temporary":"","width":"50vw","right":_vm.$root.lang != 'ar'},model:{value:(_vm.doOpenUser),callback:function ($$v) {_vm.doOpenUser=$$v},expression:"doOpenUser"}},[_c('UserPage',{ref:"userside",on:{"close":function($event){_vm.doOpenUser = false}}})],1),_c('v-navigation-drawer',{staticStyle:{"border-radius":"0"},attrs:{"app":"","temporary":"","width":"50vw","right":_vm.$root.lang != 'ar'},model:{value:(_vm.createUser),callback:function ($$v) {_vm.createUser=$$v},expression:"createUser"}},[_c('keep-alive',[_c('NewUser',{ref:"adduser",attrs:{"isActive":_vm.createUser,"role":_vm.$root.admin.selectedRole},on:{"update":_vm.showupdate,"close":function($event){_vm.createUser = false}}})],1)],1),_c('v-snackbar',{attrs:{"timeout":"5000","absolute":"","bottom":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-close")])],1)]}}],null,false,2436086175),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.msg)+" ")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }