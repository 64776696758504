<template>
  <v-list v-if="user">
    <v-form ref="form" v-model="valid" style="">
      <v-list-item class="p-0 mx-3 mb-3">
        <v-avatar v-if="newiconpreview" color="#f2f7fd" size="60">
          <v-img :src="newiconpreview" />
        </v-avatar>
        <v-avatar v-else-if="user.avatar" color="#f2f7fd" size="60">
          <v-img
            :src="$root.apiURL + 'assets/' + user.avatar.private_hash"
          ></v-img>
        </v-avatar>
        <v-avatar v-else color="#f2f7fd" size="60">
          <div
            style="text-transform: uppercase;width:100%"
            class="primary--text font-size-h1"
          >
            <span v-if="user.first_name">{{ user.first_name.charAt(0) }}</span>
            <span v-else>D</span>
          </div>
        </v-avatar>

        <v-list-item-content class="mx-3">
          <v-list-item-title v-if="newiconpreview" style="font-size:1.3rem">
            {{ newicon.name }}
          </v-list-item-title>
          <v-list-item-title v-else-if="user.avatar" style="font-size:1.3rem">
            {{ user.avatar.filename_download }}
          </v-list-item-title>
          <v-list-item-title v-else style="font-size:1.3rem">
            {{ $t("PROFILE.account.upload") }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action
          style="display: flex;flex-direction: row; min-height:40px"
        >
          <v-btn
            v-show="doedit"
            class="mx-2"
            small
            fab
            height="40px"
            elevation="0"
            color="rgba(165, 203, 239, 0.20)"
            @click="preparefile"
          >
            <unicon
              style="margin-right: auto;margin-left: auto;"
              name="upload-alt"
              fill="#0971CE"
              height="20"
            ></unicon>
          </v-btn>
          <input
            type="file"
            accept="image/png, image/gif, image/jpeg"
            class="d-none"
            ref="uploader"
            @change="processfile"
          />
          <template v-if="doedit">
            <v-btn
              @click="removeicon"
              v-show="user.avatar || newiconpreview"
              small
              fab
              elevation="0"
              color="rgba(239, 165, 179, 0.25)"
            >
              <v-icon v-if="newiconpreview" color="#f64e60">mdi-close</v-icon>
              <unicon
                v-else-if="user.avatar"
                style="margin-right: auto;margin-left: auto;"
                name="trash-alt"
                fill="#f64e60"
                height="20"
              ></unicon>
            </v-btn>
          </template>
        </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <div class="userprofile">
            <div v-if="!$root.mobile" class="title pushxl">
              {{ $t("PROFILE.account.firstname") }}
            </div>
            <v-text-field
              :disabled="!doedit"
              hide-details="auto"
              :rules="nameRules"
              :dense="!$root.mobile"
              :label="$root.mobile ? $t('PROFILE.account.firstname') : ''"
              :outlined="doedit"
              :solo="!doedit"
              flat
              v-model="user.first_name"
            />
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <div class="userprofile">
            <div v-if="!$root.mobile" class="title pushxl">
              {{ $t("PROFILE.account.lastname") }}
            </div>
            <v-text-field
              :disabled="!doedit"
              hide-details="auto"
              :rules="nameRules"
              :dense="!$root.mobile"
              :label="$root.mobile ? $t('PROFILE.account.lastname') : ''"
              :outlined="doedit"
              :solo="!doedit"
              flat
              v-model="user.last_name"
            />
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <div class="userprofile">
            <div v-if="!$root.mobile" class="title pushxl">
              {{ $t("PROFILE.account.email") }}
            </div>
            <v-text-field
              :disabled="!doedit || checkingemail"
              hide-details="auto"
              :rules="emailRules"
              :dense="!$root.mobile"
              :label="$root.mobile ? $t('PROFILE.account.email') : ''"
              :error="emailerror"
              @blur="checkemail"
              :error-messages="error_messages"
              :outlined="doedit"
              :solo="!doedit"
              flat
              ref="useremail"
              v-model="user.email"
            >
              <template v-slot:append>
                <v-fade-transition leave-absolute>
                  <v-progress-circular
                    v-if="checkingemail"
                    size="24"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </v-fade-transition>
              </template>
            </v-text-field>
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="doedit">
        <v-list-item-content>
          <div class="userprofile">
            <div v-if="!$root.mobile" class="title pushxl">
              {{ $t("PROFILE.account.newpass") }}
            </div>
            <v-row class="my-0 py-0">
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                class="my-0 "
                :class="$root.mobile ? 'pt-0' : 'py-0'"
              >
                <v-text-field
                  :disabled="!doedit"
                  hide-details="auto"
                  :dense="!$root.mobile"
                  :rules="passwordRule"
                  :label="$t('PROFILE.account.newpass')"
                  :outlined="doedit"
                  :solo="!doedit"
                  flat
                  type="password"
                  v-model="user.password"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                class="my-0 "
                :class="$root.mobile ? 'pb-0' : 'py-0'"
              >
                <v-text-field
                  :disabled="!doedit"
                  hide-details="auto"
                  :rules="confirmPasswordRules"
                  :label="$t('PROFILE.account.repeat')"
                  :dense="!$root.mobile"
                  :outlined="doedit"
                  :solo="!doedit"
                  flat
                  type="password"
                  v-model="user.confirmpassword"
                />
              </v-col>
            </v-row>
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <div class="userprofile">
            <div v-if="!$root.mobile" class="title pushxl">
              {{ $t("PROFILE.account.phone") }}
            </div>
            <v-text-field
              :disabled="!doedit"
              hide-details="auto"
              :dense="!$root.mobile"
              :label="$root.mobile ? $t('PROFILE.account.phone') : ''"
              :rules="phoneRules"
              :outlined="doedit"
              :solo="!doedit"
              flat
              v-model="user.phone"
            />
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <div class="userprofile">
            <div v-if="!$root.mobile" class="title pushxl">
              {{ $t("PROFILE.account.timezone") }}
            </div>
            <v-autocomplete
              v-model="user.timezone"
              :items="timezones"
              :filter="$util.zoneFilter"
              :disabled="!doedit"
              hide-details="auto"
              :rules="requiredFeildRule"
              :dense="!$root.mobile"
              :label="$root.mobile ? $t('PROFILE.account.timezone') : ''"
              :outlined="doedit"
              :solo="!doedit"
              flat
              item-text="zone"
              item-value="zone"
            >
              <template v-slot:selection="data">
                {{ data.item.zone }}
              </template>
              <template v-slot:item="data">
                {{ data.item.zone }}
              </template>
            </v-autocomplete>
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <div class="userprofile">
            <div v-if="!$root.mobile" class="title pushxl">
              {{ $t("PROFILE.account.lang") }}
            </div>
            <v-autocomplete
              v-model="user.preferred_lang_obj"
              :items="langs"
              :filter="$util.langFilter"
              :disabled="!doedit"
              hide-details="auto"
              :rules="requiredFeildRule"
              :dense="!$root.mobile"
              :label="$root.mobile ? $t('PROFILE.account.lang') : ''"
              :outlined="doedit"
              :solo="!doedit"
              flat
              item-text="name"
              item-value="name"
              return-object
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  @click="data.select"
                  dense
                  color="blue lighten-5"
                  class="primary--text"
                >
                  <v-avatar left>
                    <img
                      :src="
                        $root.assetsBaseURL +
                          '/public/doctory/assets/' +
                          data.item.icon
                      "
                      alt=""
                    />
                  </v-avatar>
                  {{ data.item.name }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <v-avatar class="pushxl" size="25">
                  <img
                    :src="
                      $root.assetsBaseURL +
                        '/public/doctory/assets/' +
                        data.item.icon
                    "
                    alt=""
                  /> </v-avatar
                >{{ data.item.name }}
              </template>
            </v-autocomplete>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-form>
    <v-card-actions
      v-if="user.id"
      class="p-4"
      style="height:64px;display:flex;width:100%;justify-content: center;align-items: center;background: white;
    "
    >
      <v-btn
        :loading="updating"
        :disabled="updating"
        @click="update"
        block
        elevation="0"
        rounded
        large
        color="primary"
      >
        {{ $t("PROFILE.actions.save_account") }}
      </v-btn>
    </v-card-actions>
  </v-list>
</template>

<script>
import zones from "@/core/services/data/zones.json";

export default {
  name: "account",
  components: {},
  props: ["propuser"],
  data() {
    return {
      doedit: true,
      langs: null,
      timezones: zones,
      user: this.propuser,
      newiconpreview: null,
      newicon: null,
      emailerror: false,
      valid: false,
      error_messages: [],
      checkingemail: false,
      updating: false,
      nameRules: [v => !!v || this.$t("AUTH.REG_STEPS.STEP1.validation.0")],
      emailRules: [
        v => !!v || this.$t("AUTH.LOGIN.EMAILVALIDATION"),
        v => /.+@.+/.test(v) || this.$t("AUTH.LOGIN.EMAILVALIDATION")
      ],

      phoneRules: [
        v => !!v || this.$t("AUTH.REG_STEPS.STEP3.validation.0"),
        v =>
          /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(v) ||
          this.$t("AUTH.REG_STEPS.STEP3.validation.1")
      ],
      passwordRule: [
        v =>
          Number.isInteger(this.user.id) ||
          !!v ||
          this.$t("AUTH.REG_STEPS.STEP4.validation[0]")
      ],
      requiredFeildRule: [v => !!v || this.$t("GENERAL.errors.required")],
      confirmPasswordRules: [
        v =>
          this.user.password != "" ||
          !!v ||
          this.$t("AUTH.REG_STEPS.STEP5.validation.0"),
        v =>
          this.user.password != "" ||
          v === this.user.password ||
          this.$t("AUTH.REG_STEPS.STEP5.validation.1")
      ]
    };
  },
  activated() {},
  deactivated() {
    this.$emit("removecurrentuser");
  },
  beforeMount() {},
  created() {
    this.langs = this.$util.getLanguages();
  },
  mounted() {},
  methods: {
    async update() {
      if (this.isValid()) {
        // console.log("user to udpate is", this.user)
        const payload = {
          avatar: this.user.avatar,
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          phone: this.user.phone,
          preferred_lang: this.user.preferred_lang_obj.id,
          status: this.user.status,
          timezone: this.user.timezone
        };
        if (this.newicon) {
          var formData = new FormData();
          formData.append("data", this.newicon);
          const { data } = await this.$api.simplePostFile("files", formData);
          //// console.log("file upload result id",data.data.id)
          payload.avatar = data.data.id;
        }
        if (this.user.password != undefined)
          payload.password = this.user.password;

        // console.log("final payload", payload);
        this.updating = true;
        await this.$user.updateUser(this.user.id, payload).then(data => {
          if (data.status == 200) {
            if (this.user.role.id === 3) {
              this.$doctor.updateDoctorCache(this.user.id);
            }
            this.updating = false;
          }
        });
      }
    },
    async checkemail() {
      if (this.$refs.useremail.valid) {
        this.checkingemail = true;
        await this.$user.checkEmail(this.user.email).then(data => {
          if (data.data.data.length > 0) {
            this.error_messages.push(
              this.$t("AUTH.REG_STEPS.STEP2.validation.2")
            );
            this.emailerror = true;
          } else {
            this.error_messages = [];
            this.emailerror = false;
          }
        });
        this.checkingemail = false;
      }
    },
    reset() {
      // console.log("resetting user form");
      this.$refs.form.resetValidation();
      this.user = {
        first_name: "",
        last_name: "",
        role: this.role,
        email: "",
        password: "",
        timezone: "",
        phone: "",
        preferred_lang: "",
        preferred_lang_obj: this.$util.getLanguages()[0],
        verify_email: "false",
        nationality: "",
        national_id: ""
      };
    },
    isValid() {
      return !this.$refs.useremail.error && this.$refs.form.validate();
    },
    getUser() {
      if (this.newicon) this.user.newavatar = this.newicon;
      return this.user;
    },
    setUser() {},
    preparefile() {
      this.$refs.uploader.click();
    },
    processfile(e) {
      this.newicon = e.target.files[0];
      this.newiconpreview = URL.createObjectURL(e.target.files[0]);
    },
    removeicon() {
      if (this.newicon) {
        this.newicon = null;
        this.newiconpreview = null;
      } else if (this.user.avatar) {
        this.user.avatar = null;
      }
    }
  },
  computed: {}
};
</script>

<style></style>
